<template>
  <div class="derivative">
    <img src="../../assets/product/derivative/1.png" class="headerimg" />

    <div class="store">
      <div class="title">功能丰富的小程序商城</div>
      <div class="storelist">
        <div class="storecard">
          <div class="storeblock">1</div>
          <div class="storeinfo">
            <div class="storetitle">多平台开店</div>
            <div class="storetext">
              支持微信、QQ、百度、支付宝等多平台小程序商城一键生成
            </div>
          </div>
        </div>

        <div class="storecard">
          <div class="storeblock">2</div>
          <div class="storeinfo">
            <div class="storetitle">丰富营销插件</div>
            <div class="storetext">拼团、砍价、秒杀、满减满折等</div>
          </div>
        </div>

        <div class="storecard">
          <div class="storeblock">3</div>
          <div class="storeinfo">
            <div class="storetitle">SCRM会员管理</div>
            <div class="storetext">
              SCRM会员管理
              <div>积分商城提升复购促进留存</div>
            </div>
          </div>
        </div>
      </div>

      <div class="storelist">
        <div class="storecard">
          <div class="storeblock">4</div>
          <div class="storeinfo">
            <div class="storetitle">快速裂变分销</div>
            <div class="storetext">裂变分销快速拓展销售渠道 促进业绩增长</div>
          </div>
        </div>

        <div class="storecard">
          <div class="storeblock">5</div>
          <div class="storeinfo">
            <div class="storetitle">企微私域赋能</div>
            <div class="storetext">
              强化企业微信能力
              <div>提供全面的私域用户管理运营服务</div>
            </div>
          </div>
        </div>

        <div class="storecard">
          <div class="storeblock">6</div>
          <div class="storeinfo">
            <div class="storetitle">完善开发者生态</div>
            <div class="storetext">
              1000+平台应用，560+第三方开发者
              <div>为商户提供更多服务选择</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="case">
      <div class="title">微商城小程序解决方案</div>
      <div class="caselist">
        <div class="casecard">
          <div class="casetitle">获客</div>
          <div class="caseinfo">
            <div class="caseline">1.多渠道流量获取，帮助商家快速获客</div>
            <div class="caseline">
              2.60多个超级流量入口，带来更多微信用户流量
            </div>
            <div class="caseline">
              3.3招搞定零售商家获客难题，获客拉新不用愁
            </div>
            <div class="caseline">
              4.朋友圈广告+微商城小程序让你的店铺获编朋友圈
            </div>
          </div>
        </div>
        <div class="casecard">
          <div class="casetitle">转化</div>
          <div class="caseinfo">
            <div class="caseline">
              1.可视化组件随意拖拽，搭建商家自由个性化商城
            </div>
            <div class="caseline">2.几十余种店铺组件选择，灵活装扮店铺</div>
            <div class="caseline">
              3.多种营销插件，构建以消费者为中心的互动体验转化利器
            </div>
            <div class="caseline">
              4.特权价，享受专属尊享商品，提升客户消费体验
            </div>
          </div>
        </div>
        <div class="casecard">
          <div class="casetitle">裂变</div>
          <div class="caseinfo">
            <div class="caseline">
              1.社交裂变，一生十，十生百，助力商家销量增倍
            </div>
            <div class="caseline">2.拼团，呼朋组团享优惠</div>
            <div class="caseline">3.社区团购，社群营销促销量</div>
            <div class="caseline">4.砍价，一传十，十传百，裂变营销</div>
          </div>
        </div>
        <div class="casecard">
          <div class="casetitle">复购</div>
          <div class="caseinfo">
            <div class="caseline">1.强大的会员管理系统，精准化营销</div>
            <div class="caseline">2.数字化管理体系，让客户二次复购变得简单</div>
            <div class="caseline">3.两种会员模式，多种权益享不停</div>
            <div class="caseline">4.积分权益，会员等级促进复购</div>
          </div>
        </div>
        <div class="casecard">
          <div class="casetitle">提效</div>
          <div class="caseinfo">
            <div class="caseline">1.渠道追踪数据来源，提升营销效率</div>
            <div class="caseline">2.强大的数据系统驱动店铺运营</div>
            <div class="caseline">3.专业数据报表，多维度分析管理呈现数据</div>
            <div class="caseline">4.在线客服系统，实时信息交流促转化</div>
          </div>
        </div>
      </div>
    </div>

    <div class="der">
      <div
        class="derlist"
        v-for="(item, index) in list"
        :key="index"
        :class="index % 2 == 1 ? 'bacolor' : ''"
      >
        <div class="dercard" v-if="index % 2 == 0">
          <div class="derimg">
            <img :src="item.imgurl" alt="" />
          </div>
          <div class="derinfo">
            <div class="dertitle">
              {{ item.title }}
              <div>{{ item.description }}</div>
            </div>
            <div class="dertext">
              {{ item.info }}
            </div>
          </div>
        </div>
        <div class="dercard" v-else>
          <div class="derinfo">
            <div class="dertitle">
              {{ item.title }}
              <div>{{ item.description }}</div>
            </div>
            <div class="dertext">
              {{ item.info }}
            </div>
          </div>
          <div class="derimg">
            <img :src="item.imgurl" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="serve">
      <div class="title">360°多方位服务体系</div>
      <div class="servelist">
        <div class="servecard">
          <div class="serveimg">
            <img src="../../assets/product/derivative/9.png" alt="" />
          </div>
          <div class="servetitle">专业客服支持</div>
          <div class="serveinfo">
            7*24小时一对一客服
            <div>全程在线服务</div>
          </div>
        </div>
        <div class="servecard">
          <div class="serveimg">
            <img src="../../assets/product/derivative/10.png" alt="" />
          </div>
          <div class="servetitle">强大代运营服务</div>
          <div class="serveinfo">
            优秀代运营团队
            <div>提供可靠的代运营服务</div>
          </div>
        </div>
        <div class="servecard">
          <div class="serveimg">
            <img src="../../assets/product/derivative/11.png" alt="" />
          </div>
          <div class="servetitle">移动广告投放</div>
          <div class="serveinfo">
            优质广告资源覆盖海量用户
            <div>精准定向目标受众</div>
          </div>
        </div>
      </div>
    </div>

    <div class="system">
      <div class="title">360°多方位服务体系</div>
      <div class="systemimg">
        <img src="../../assets/product/derivative/12.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          imgurl: require("../../assets/product/derivative/2.png"),
          title: "多平台一键开店",
          description: "聚合流量曝光引流",
          info: "微信、抖音、快手、支付宝、百度、QQ、QQ浏览器、视频号等多个平台，一键开店，统一管理，全方位引流",
        },
        {
          imgurl: require("../../assets/product/derivative/3.png"),
          title: "完整开店系统",
          description: "轻松搭建在线商城",
          info: "数十种装修组件，上百套行业模板，满足不同行业搭建需求帮助商家快速搭建属于自己的个性化独立商城",
        },
        {
          imgurl: require("../../assets/product/derivative/4.png"),
          title: "百余种营销玩法",
          description: "带动高活跃高转化",
          info: "拼团、砍价、直播等多种营销插件组合营销，刺激客户购买，带动商家业绩高效增长",
        },
        {
          imgurl: require("../../assets/product/derivative/5.png"),
          title: "分销裂变提高销量",
          description: "让人人都是商家分销员",
          info: "构建完整分销体系，佣金奖励、等级刺激、功能灵活，全面满足商家全场景营销需求",
        },
        {
          imgurl: require("../../assets/product/derivative/6.png"),
          title: "SCRM客户管理",
          description: "精细化运营提高复购",
          info: "会员全周期管理，精细化运营客户，精准营销增强客户粘性带来高复购",
        },
        {
          imgurl: require("../../assets/product/derivative/7.png"),
          title: "企微能力全面升级",
          description: "赋能商家私域长效增长",
          info: "集合移动店铺及客户管理能力，实现企业微信的升级强化，为商户提供全面精细的私域运营服务",
        },
        {
          imgurl: require("../../assets/product/derivative/8.png"),
          title: "全面领先的数据分析",
          description: "经营分析提升营收",
          info: "多角度、全方位的店铺经营分析，数据驱动营销决策促进业绩增长",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.system {
  margin-top: 140px;
  padding: 90px;
  background: #f2f3fa;
  .systemimg {
    text-align: center;
    img {
      width: 1401px;
    }
  }
}

.serve {
  padding-top: 90px;
  padding-bottom: 140px;
  background: #f5f7fa;
  .servelist {
    margin: auto;
    margin-top: 60px;
    width: 80%;
    display: flex;
    justify-content: space-between;
    .servecard {
      width: 397px;
      height: 357px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      text-align: center;
      .serveimg {
        margin: 60px 0px;
        img {
          width: 90px;
        }
      }
      .servetitle {
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
      }
      .serveinfo {
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
        margin-top: 20px;
      }
    }
  }
}

.der {
  margin-top: 50px;
  .bacolor {
    background: #f5f7fa;
  }
  .derlist {
    padding: 90px 0px;
    .dercard {
      margin: auto;
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .derimg {
        img {
          width: 531px;
        }
      }
      .derinfo {
        width: 700px;
        .dertitle {
          font-size: 36px;
          font-weight: 500;
          color: #333333;
          line-height: 50px;
        }
        .dertext {
          font-size: 24px;
          font-weight: 500;
          color: #616b80;
          line-height: 40px;
          margin-top: 40px;
        }
      }
    }
  }
}

.case {
  .caselist {
    width: 80%;
    margin: auto;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    .casecard {
      width: 259px;
      height: 352px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      border-radius: 14px 14px 0px 0px;
      box-sizing: border-box;
      .casetitle {
        width: 259px;
        height: 82px;
        background: linear-gradient(135deg, #b933da 0%, #2a7ae5 100%);
        border-radius: 14px 14px 0px 0px;
        opacity: 0.5;
        color: #fff;
        font-size: 30px;
        font-weight: 500;
        line-height: 82px;
        text-align: center;
      }
      .caseinfo {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #616b80;
        line-height: 20px;
        padding: 0px 39px;
        .caseline {
          margin-top: 10px;
        }
      }
    }
  }
}

.store {
  margin: 140px auto;
  .storelist {
    width: 80%;
    margin: auto;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    .storecard {
      .storeinfo {
        display: inline-block;
        vertical-align: top;
        .storetitle {
          font-size: 24px;
          font-weight: 500;
          color: #333333;
          line-height: 36px;
        }
        .storetext {
          margin-top: 20px;
          width: 204px;
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 20px;
        }
      }
      .storeblock {
        margin-right: 20px;
        background: linear-gradient(135deg, #b933da 0%, #2a7ae5 100%);
        text-align: center;
        box-sizing: border-box;
        padding: 5px 17px;
        color: #fff;
        font-size: 24px;
        display: inline-block;
      }
    }
  }
}
</style>